import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client'
import fetch from 'isomorphic-fetch'

export const Client = new ApolloClient({
	cache: new InMemoryCache(),
	link: new HttpLink({
		uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master?access_token=${process.env.GATSBY_CONTENTFUL_DELIVERY_TOKEN}`,
		fetch,
	}),
})
