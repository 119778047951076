import React, { useState, useEffect } from 'react'
import AddProduct from './add-product'
import TabDetails from './tab-details'
import useProduct from '../../../../sea-site-theme/src/hooks/useProduct'
import { TabsContainer, TabsInner, Tab } from './styles'
import { ProductImage } from '../../../../sea-site/src/pages/product-comparison'

interface TabsProps {
	title: string
	tabsArray: {
		tabName: string
		brand: string
		tag: string
		slug: string
		active: boolean
	}[]
	imageNode: ProductImage[]
}

const Tabs = ({ title, tabsArray, imageNode }: TabsProps): JSX.Element => {
	const [activeBtn, setActiveBtn] = useState(tabsArray)
	const [activeSlug, setActiveSlug] = useState(tabsArray[0].slug)
	const [sessionSlug, setCurrentSessionSlug] = useState(tabsArray[0].slug)
	const [split, setSplit] = useState('')
	const [addProductDetails, setAddProductDetails] = useState([])
	const [tabDetails, setTabDetails] = useState([])

	const activeTabHandler = (index: number, slug: string) => {
		const active = activeBtn.map((x, i) => {
			return i === index ? { ...x, active: true } : { ...x, active: false }
		})
		setActiveSlug(slug)
		setActiveBtn(active)
	}
	useEffect(() => {
		const windowHash = window.location.hash
		const splitWindowHash = windowHash.split('#').join('')
		setSplit(splitWindowHash)
	})

	useEffect(() => {
		if (split === '') {
			sessionStorage.setItem('comparisontab', activeSlug)
			setCurrentSessionSlug(activeSlug)
			const firstBtnActive = { ...activeBtn[0], active: true }
			const btns = [...activeBtn]
			btns[0] = firstBtnActive
			setActiveBtn(btns)
		} else if (split != '') {
			const xx = activeBtn.map((x) => {
				return x.slug === split ? { ...x, active: true } : { ...x, active: false }
			})
			setActiveBtn(xx)
			sessionStorage.setItem('comparisontab', split)
			setCurrentSessionSlug(split)
		}

		return () => {
			sessionStorage.removeItem('comparisontab')
		}
	}, [activeSlug, split])

	const getDatas = (dits: string) => {
		const parsedDetails = JSON.parse(dits)
		setAddProductDetails(parsedDetails)
	}

	const getTabDetails = (tabDits: string) => {
		const parsedDetails = JSON.parse(tabDits)
		setTabDetails(parsedDetails)
	}

	const active = activeBtn.filter((item) => item.active === true)
	const dolphinTag = activeBtn.filter((item) => (item.tag ? (item.active === true ? item.tag : '') : ''))
	const { tabDataSummary } = useProduct()
	return (
		<TabsContainer>
			<TabsInner>
				{activeBtn.map((item, i) => {
					return (
						<Tab
							key={i}
							className={item.active ? 'active' : ''}
							href={`#${item.slug}`}
							onClick={() => {
								getTabDetails(tabDataSummary(item.tag ? item.tag : item.slug))
								activeTabHandler(i, item.slug)
							}}
						>
							{item.tabName}
						</Tab>
					)
				})}
				<AddProduct {...{ title, active, dolphinTag, getDatas, imageNode }} />
			</TabsInner>
			<TabDetails activeSlug={sessionSlug} addDetails={addProductDetails} tabDetails={tabDetails} />
		</TabsContainer>
	)
}

export default Tabs
